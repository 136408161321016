// thêm những module bootstrap để sử dụng
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/offcanvas';
import 'bootstrap/js/dist/dropdown';

// Import all images from the specified directory and its subdirectories
// This is typically used in build tools like Vite to include assets
import.meta.glob(["../img/**"]);

// Import Alpine.js library
import Alpine from 'alpinejs';

// Attach Alpine to the global window object
// This allows it to be accessible globally in the browser
window.Alpine = Alpine;

// Adding an event listener for the 'alpine:init' event
document.addEventListener('alpine:init', () => {

    // Defining a new Alpine.js data component called 'scrollSection'
    Alpine.data('scrollSection', () => ({
        // Property to store the current section identifier
        currentSection: '',

        // Method to scroll to a specific section identified by dataSection
        scrollToSection(dataSection) {
            // Remove the hash from the URL without causing a page reload
            if (window.location.hash) {
                history.replaceState(null, null, window.location.pathname);
            }

            // Find the section element with the specified data-section attribute and scroll to it
            document.querySelector('[data-section="' + dataSection + '"]').scrollIntoView();
        },

        // Method to update the current section based on the scroll position
        updateCurrentSection() {
            // Select all elements with a data-section attribute
            let sections = document.querySelectorAll('[data-section]');
            // Get the viewport height
            let viewportHeight = window.innerHeight;

            // Iterate over each section element
            sections.forEach(section => {
                // Get the bounding rectangle of the section
                let rect = section.getBoundingClientRect();
                // Check if the top of the section is within the top half of the viewport
                if (rect.top >= 0 && rect.top < viewportHeight / 2) {
                    // Update the currentSection property with the section's data-section attribute value
                    this.currentSection = section.getAttribute('data-section');
                }
            });
        },

        // Initialization method to set up event listeners and initial state
        init() {
            // Add a scroll event listener to update the current section on scroll
            window.addEventListener('scroll', this.updateCurrentSection.bind(this));
            // Call updateCurrentSection once to set the initial section on page load
            this.updateCurrentSection();
        }
    }));
});

// Start Alpine.js, initializing its functionality
Alpine.start();


// Add an event listener to the DOMContentLoaded event, which fires when the initial HTML document has been completely loaded and parsed
document.addEventListener("DOMContentLoaded", async () => {
    try {
        // Dynamically import the 'lazysizes' library
        const { default: lazySizes } = await import('lazysizes');

        // Select all img elements that do not have the class 'lazyload'
        const images = document.querySelectorAll("img:not(.lazyload)");

        // If no images are found, exit the function early
        if (!images.length) return;

        // Select the body element as the container
        const container = document.body;

        // Define a handler function for the load event
        const imageLoadHandler = (event) => {
            const img = event.target; // Get the target of the event

            // If the target is an img element and it is not complete, exit the function early
            if (img.tagName === 'IMG' && !img.complete) return;

            // If all images are loaded, initialize lazySizes and remove the event listener
            if (isAllImagesLoaded(images)) {
                lazySizes.init();
                container.removeEventListener("load", imageLoadHandler, true);
            }
        };

        // Add the load event listener to the container (body), with capture set to true
        container.addEventListener("load", imageLoadHandler, true);

        // If all images are already loaded, initialize lazySizes immediately
        if (isAllImagesLoaded(images)) {
            lazySizes.init();
        }
    } catch (error) {
        // Log any errors that occur during the import or initialization process
        console.error('An error occurred:', error);
    }


});

// Helper function to check if all images in the NodeList are loaded
const isAllImagesLoaded = (images) => Array.from(images).every(img => img.complete);
